<template>
  <div>
    <div class="row" style="box-shadow: 0px 4px 2px #acaaaa">
      <div class="col-md-2">
        <h6>
          Service Period <br />
          <small class="text-color"
            >{{ dobformat2(form.dates.startDate) }} -
            {{ dobformat2(form.dates.endDate) }}</small
          >
        </h6>
      </div>
      <div class="col-md-3">
        <v-text-field
          class="pt-0 mt-0"
          label="Search by Name or ID"
          v-model="Search"
        ></v-text-field>
      </div>
      <div>
        <a @click="Apply" class="btn btn-success btn-sm"
          >Refresh
          <span v-if="loader">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </a>
      </div>
    </div>

    <div v-if="!loader" class="row mt-9 pt-12" style="height: 140px">
      <div class="col-md-6">
        <div>
          <h4>Search Results</h4>
        </div>
        <div class="d-flex">
          <h6>{{ numberOfRecord }}</h6>
          <span class="pl-2">Records(s) Founds</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <h6>
              {{ payableHours }} <br />
              <small class="text-color"> <b>Hours</b> </small>
            </h6>
          </div>
          <div class="col-md-3">
            <h6>
              $ {{ payableRate }}<br />
              <small class="text-color"><b>Payroll Total</b></small>
            </h6>
          </div>
          <div class="col-md-3">
            <h6>
              {{ noOfCareGiver }}<br />
              <small class="text-color"><b>Caregivers(s) </b></small>
            </h6>
          </div>
          <div class="col-md-3">
            <h6>
              {{ noOfClient }}<br />
              <small class="text-color"><b>Client(s)</b></small>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loader"
      class="container mt-15"
      style="background-color: #ffffff"
    >
      <!-- <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        select-mode="single"
        :fields="fields"
        selectable
        striped
        hover
      >
      </b-table> -->
      <v-data-table
        :headers="headersParent"
        :items="freshRecord"
        :expanded.sync="expanded"
        item-key="caregiverName"
        class="elevation-1 ParentTable"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <!-- <template #[`item.data-table-select`]="{ isSelected, select }">
          <v-simple-checkbox
            color="primary"
            :value="isSelected"
            @input="select($event)"
          ></v-simple-checkbox>
        </template> -->

        <template #[`item.empName`]="{ item, index }">
          <td class="px-0 py-2">
            <v-data-table
              :headers="index === 0 ? headersPayrate : headersPayrate2"
              :items="item.rates"
              :hide-default-footer="true"
              :key="index"
            >
              <template #[`item.WorkerName`]="{ item }">
                <span style="color: blue; font-weight: bold">{{
                  item.caregiverName
                }}</span>
              </template>
              <template #[`item.WorkerID`]="{ item }">
                <span style="color: blue; font-weight: bold">{{
                  item.empID
                }}</span>
              </template>
              <template #[`item.PayComp`]="{ item }">
                <span style="color: blue; font-weight: bold">Regular</span>
              </template>
              <template #[`item.Hours`]="{ item }">
                <span style="color: blue; font-weight: bold">{{
                  HoursAdd(item)
                }}</span>
              </template>
              <template #[`item.Rate`]="{ item }">
                <span style="color: blue; font-weight: bold">{{
                  item.payRate
                }}</span>
              </template>
              <template #[`item.Subcode`]="{ item }">
                <span style="color: blue; font-weight: bold"></span>
              </template>
              <template #[`item.Client`]="{ item }">
                <span style="color: blue; font-weight: bold"></span>
              </template>
              <template #[`item.DateRange`]="{ item }">
                <span style="color: blue; font-weight: bold"></span>
              </template>
            </v-data-table>
          </td>
        </template>

        <template #expanded-item="{ headers, item }">
          <td class="px-0 py-2" :colspan="headers.length">
            <v-data-table
              v-model="selectedChild"
              :headers="headersChild"
              :items="item.data"
              item-key="name"
              elevation="0"
            >
              <template #[`item.WorkerName`]="{ item }">
                <span>{{ item.caregiverName }}</span>
              </template>
              <template #[`item.WorkerID`]="{ item }">
                <span>{{ item.empID }}</span>
              </template>
              <template #[`item.PayComp`]="{ item }">
                <span>Regular</span>
              </template>
              <template #[`item.Hours`]="{ item }">
                <span>{{ item.actualHours.toFixed(2) }}</span>
              </template>
              <template #[`item.Rate`]="{ item }">
                <span>{{ item.payRate }}</span>
              </template>
              <template #[`item.Subcode`]="{ item }">
                <span>{{ item.subCode }}</span>
              </template>
              <template #[`item.Client`]="{ item }">
                <span>{{ item.clientName }}</span>
              </template>
              <template #[`item.DateRange`]="{ item }">
                {{
                  dobformat(
                    item.editStartTimeDate != null
                      ? item.editStartTimeDate
                      : item.startTimeDate
                  )
                }}
                -
                {{
                  dobformat(
                    item.editEndTimeDate != null
                      ? item.editEndTimeDate
                      : item.endTimeDate
                  )
                }}
              </template>
              <!-- <template #[`header.data-table-select`]="{ on, props }">
                <v-simple-checkbox
                  color="purple"
                  v-bind="props"
                  v-on="on"
                ></v-simple-checkbox>
              </template> -->

              <!-- <template #[`item.data-table-select`]="{ isSelected, select }">
                <v-simple-checkbox
                  color="purple"
                  :value="isSelected"
                  @input="select($event)"
                ></v-simple-checkbox>
              </template> -->

              <template #[`item.startTimeDate`]="{ item }">
                {{ dobformat(item.startTimeDate) }}s -
                {{ dobformat(item.endTimeDate) }}
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>

    <div style="height: 100px">
      <div class="float-right mt-10">
        <span>
          <a class="btn btn-success btn-sm" @click="ExportPaychex"
            >Download CSV</a
          >
        </span>
        <span class="m-4">
          <a class="btn btn-success btn-sm" @click="CloseModel">Cancel </a>
        </span>
        <div>
          <b-modal
            id="modal-center"
            v-model="modalShow"
            no-close-on-backdrop
            centered
            title="BootstrapVue"
            hide-footer
          >
            <template #modal-header="{ close }">
              <h5>Process Payroll</h5>
              <v-icon class="btn btn-sm close-btn" @click="close()"
                >mdi-close</v-icon
              >
            </template>
            <p class="my-4">Batch name is : {{ form.batchName }}</p>
            <div style="text-align: center" class="modal-footer">
              <span class="mx-4 my-0">
                <a class="btn btn-success btn-sm" @click="ExportPaychex"
                  >Paychex export
                </a>
              </span>
              <span class="mx-4 my-0">
                <a class="btn btn-success btn-sm" @click="htmlToCSV"
                  >Export to Excel
                </a>
              </span>
              <!-- <span class="mx-4 my-0">
                <a class="btn btn-danger btn-sm" @click="RESET"
                  >Delete Batch
                </a>
              </span> -->
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <b-modal
      centered
      text
      id="confirmAuthModal"
      title="Are you sure you want to Save this?"
      hide-footer
    >
      <div class="text-center">
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="SaveBatch"
            ><span
              >Save
              <span v-if="loader">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('CurrentPayroll')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "currentPayroll",
  props: ["form"],
  data() {
    return {
      freshRecord: [],
      modalShow: false,
      closemodel: true,
      selected: [],
      items: [],
      Search: "",
      loader: false,
      Services: [],
      headersParent: [
        // {
        //   text: 'Select',
        //   align: 'left',
        //   value: 'data-table-select',
        //   class: 'checkbox',
        //   cellClass: 'checkbox'
        // },
        {
          text: "",
          align: "left",
          value: "empName",
          width: "100%",
          sortable: false,
        },
        // { text: "Date Range", value: "startTimeDate", width: "15%" },

        // //{ text: "End Time", value: "endTimeDate" ,    width:'15%'},
        // { text: "Service/Sub Code", value: "subCode", width: "15%" },
        // {
        //   text: "Client",
        //   align: "left",
        //   value: "clientName",
        //   width: "15%",
        // },
        // { text: "Hours", value: "payableHours", width: "15%" },

        // { text: "Rate", value: "payRate", width: "15%" },
        // { text: "OT", value: "OT", width: "15%" },
        // { text: "OT Rate", value: "OT Rate", width: "15%" },
      ],
      headersPayrate: [
        {
          text: "Worker Name",
          align: "left",
          value: "WorkerName",
          width: "15%",
          sortable: false,
        },
        {
          text: "Worker ID",
          align: "left",
          value: "WorkerID",
          width: "15%",
          sortable: false,
        },
        {
          text: "Pay Comp",
          align: "left",
          value: "PayComp",
          width: "15%",
          sortable: false,
        },
        {
          text: "Hours",
          align: "left",
          value: "Hours",
          width: "15%",
          sortable: false,
        },
        {
          text: "Rate",
          align: "left",
          value: "Rate",
          width: "15%",
          sortable: false,
        },
        {
          text: "Service/Subcode",
          align: "left",
          value: "Subcode",
          width: "15%",
          sortable: false,
        },
        {
          text: "Client",
          align: "left",
          value: "Client",
          width: "15%",
          sortable: false,
        },
        {
          text: "Date Range",
          align: "left",
          value: "DateRange",
          width: "15%",
          sortable: false,
        },
        // { text: "Hours", value: "payableHours", width: "15%" },
        // { text: "Rate", value: "payRate", width: "15%" },
        // { text: "OT", value: "OT", width: "15%" },
        // { text: "OT Rate", value: "OT Rate", width: "15%" },
      ],
      headersChild: [
        {
          text: " ",
          align: "left",
          value: "WorkerName",
          width: "16.8%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "WorkerID",
          width: "16.8%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "PayComp",
          width: "16.8%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Hours",
          width: "16.8%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Rate",
          width: "16.8%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Subcode",
          width: "16.8%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Client",
          width: "16.8%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "DateRange",
          width: "16.8%",
          sortable: false,
        },
        // { text: "Hours", value: "payableHours", width: "15%" },
        // { text: "Rate", value: "payRate", width: "15%" },
        // { text: "OT", value: "OT", width: "15%" },
        // { text: "OT Rate", value: "OT Rate", width: "15%" },
      ],
      headersPayrate2: [
        {
          text: " ",
          align: "left",
          value: "WorkerName",
          width: "14.4%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "WorkerID",
          width: "14.4%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "PayComp",
          width: "14.4%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Hours",
          width: "14.4%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Rate",
          width: "14.4%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Subcode",
          width: "14.4%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "Client",
          width: "14.4%",
          sortable: false,
        },
        {
          text: " ",
          align: "left",
          value: "DateRange",
          width: "14.4%",
          sortable: false,
        },
        // { text: "Hours", value: "payableHours", width: "15%" },
        // { text: "Rate", value: "payRate", width: "15%" },
        // { text: "OT", value: "OT", width: "15%" },
        // { text: "OT Rate", value: "OT Rate", width: "15%" },
      ],
      treats: [],
      fields: [
        {
          label: "Employee",
          key: "caregiverName",
        },
        {
          label: "Client",
          key: "clientName",
        },
        {
          label: "Start Time",
          key: "startTimeDate",
        },
        {
          label: "End Time",
          key: "endTimeDate",
        },
        {
          label: "ServiceorSubCode",
          key: "subCode",
        },
        {
          label: "Hours",
          key: "payableHours",
        },
        {
          label: "Rate",
          key: "payRate",
        },
      ],
    };
  },
  watch: {
    Search: {
      handler: function () {
        this.searchedData();
      },
    },
  },
  computed: {
    ...mapGetters([
      "TimePeriod",
      "getWorkedHour",
      "ClientCount",
      "GetAuthenticatedUser",
    ]),

    numberOfRecord() {
      var totalRecords = 0;
      this.freshRecord.forEach((e) => {
        totalRecords += e.data.length;
      });
      return totalRecords;
    },

    payableHours() {
      if (this.freshRecord) {
        var hours = 0;
        this.freshRecord.forEach((element) => {
          element.data.forEach((p) => {
            hours = hours + p.actualHours;
          });
        });

        return Math.round(hours * 100) / 100;
      } else {
        return 0;
      }
    },
    payableRate() {
      if (this.freshRecord) {
        var rate = 0;
        this.freshRecord.forEach((element) => {
          element.data.forEach((p) => {
            var amount = (Math.round(p.payableHours * 100) / 100) * p.payRate;
            rate = rate + amount;
          });
        });

        return Math.round(rate * 100) / 100;
      } else {
        return 0;
      }
    },
    noOfCareGiver() {
      if (this.freshRecord) {
        return this.freshRecord.length;
      } else {
        return 0;
      }
    },
    noOfClient() {
      if (this.freshRecord) {
        return this.ClientCount;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    if (this.GetAuthenticatedUser.userType == "JFS") {
      this.freshRecord = this.TimePeriod.filter(
        (x) => x.employeeType == "JFS" || x.companyName == "JFS"
      );
    } else if (this.GetAuthenticatedUser.userType == "Home Health") {
      this.freshRecord = this.TimePeriod.filter(
        (x) =>
          x.employeeType == "Home Health" ||
          x.companyName == "Regional Center/Medi-Cal"
      );
    } else if (this.GetAuthenticatedUser.userType == "Super Admin") {
      if (this.form.departments.length == 0) {
        this.freshRecord = this.TimePeriod;
      } else {
        this.freshRecord = this.TimePeriod.filter((x) =>
          this.form.departments.includes(x.employeeType)
        );
      }
    } else {
      this.freshRecord = this.TimePeriod;
    }
  },
  methods: {
    searchedData() {
      if (this.Search == "") {
        if (this.GetAuthenticatedUser.userType == "JFS") {
          this.freshRecord = this.TimePeriod.filter(
            (x) => x.employeeType == "JFS" || x.companyName == "JFS"
          );
        } else if (this.GetAuthenticatedUser.userType == "Home Health") {
          this.freshRecord = this.TimePeriod.filter(
            (x) =>
              x.employeeType == "Home Health" ||
              x.companyName == "Regional Center/Medi-Cal"
          );
        } else if (this.GetAuthenticatedUser.userType == "Super Admin") {
          if (this.form.departments.length == 0) {
            this.freshRecord = this.TimePeriod;
          } else {
            this.freshRecord = this.TimePeriod.filter((x) =>
              this.form.departments.includes(x.employeeType)
            );
          }
        } else {
          this.freshRecord = this.TimePeriod;
        }
      } else {
        this.freshRecord = this.freshRecord.filter((e) => {
          return (
            e.empID.includes(this.Search) ||
            e.caregiverName.toLowerCase().includes(this.Search.toLowerCase())
          );
        });
      }
    },
    HoursAdd(hours) {
      var sum = 0;
      this.freshRecord.forEach((t) => {
        if (t.empID == hours.empID) {
          t.data.forEach((r) => {
            if (r.payRate == hours.payRate) {
              sum += r.actualHours;
            }
          });
        }
      });

      return sum.toFixed(2);
    },
    async SaveBatch() {
      this.loader = true;
      await this.$store
        .dispatch("addBatch", {
          batchName: this.form.batchName,
          payDate: this.dobformat2(this.form.date),
          startDate: this.dobformat2(this.form.dates.startDate),
          endDate: this.dobformat2(
            new Date(this.form.dates.endDate).setDate(
              new Date(this.form.dates.endDate).getDate() + 1
            )
          ),
        })
        .then((res) => {
          if (res.status) {
            this.$bvModal.hide("confirmAuthModal");
            this.modalshow();
          } else {
            Swal.fire({
              title: "",
              text: res.message,
              icon: "error",
              confirmButtonClass: "btn btn-danger",
            });
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    ExportPaychex() {
      var filename =
      this.form.selectedDepartment +
        "_" +
        (new Date(this.form.dates.startDate).getMonth() + 1) +
        "_" +
        new Date(this.form.dates.startDate).getDate() +
        "_" +
        new Date(this.form.dates.startDate).getFullYear() +
        "_" +
        (new Date(this.form.dates.endDate).getMonth() + 1) +
        "_" +
        new Date(this.form.dates.endDate).getDate() +
        "_" +
        new Date(this.form.dates.endDate).getFullYear();
      var Services = [];
      var Employee = [];
      var data = [];
      var header = "";
      this.freshRecord.forEach((T) => {
        if (!Services.includes(T.payRate)) {
          Services.push(T.payRate);
        }
      });
      this.freshRecord.forEach((T) => {
        if (!Employee.includes(T.caregiverName)) {
          Employee.push(T.caregiverName);
        }
      });

      header = `"Client ID","Worker Name","Worker ID",Org,"Job Number","Pay Component",Rate,"Rate Number","Hours",Units,"Line Date",Amount,"Check Seq Number","Override Local","Override Local Jurisdiction","Labor Assignment","Override State"`;
      data.push(header);
      Employee.forEach((E, Ei) => {
        this.freshRecord.forEach((T) => {
          if (T.caregiverName == E) {
            T.rates.forEach((p) => {
              var row = `"A8302570 ","${T.caregiverName}","'${
                T.empID
              }'","","","Regular","${p.payRate}","","${this.HoursAdd(
                p
              )}","","","","","","","",""`;
              data.push(row);
            });
          }
        });
      });
      this.downloadCSVFile(data.join("\n"), filename);
    },
    htmlToCSV() {
      var filename = this.form.batchName;
      var Services = [];
      var Employee = [];
      var data = [];
      var header = "";
      this.freshRecord.forEach((T) => {
        if (!Services.includes(T.payRate)) {
          Services.push(T.payRate);
        }
      });
      this.freshRecord.forEach((T) => {
        if (!Employee.includes(T.caregiverName)) {
          Employee.push(T.caregiverName);
        }
      });

      header = `"Client ID","Worker Name","Worker ID",Org,"Job Number","Pay Component",Rate,"Rate Number","Hours",Units,"Line Date",Amount,"Check Seq Number","Override Local","Override Local Jurisdiction","Labor Assignment","Override State"`;
      data.push(header);
      this.TimePeriod.forEach((T, ti) => {
        T.data.forEach((p) => {
          if (p.caregiverName == T.caregiverName) {
            var row = `"A8302570","${T.caregiverName}","'${
              T.empID
            }'","","","Regular","${p.payRate}","","${p.payableHours.toFixed(
              2
            )}","","","","","","","",""`;
            data.push(row);
          }
        });

        data.push("");
        data.push("");
      });
      this.downloadCSVFile(data.join("\n"), filename);
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    dobformat(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear() +
          " " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        );
      }
    },
    modalshow() {
      this.modalShow = true;
    },
    async RESET() {
      await this.$store
        .dispatch("removepayRoll", this.TimePeriod[0].payRollId)
        .then((r) => {
          Swal.fire({
            title: "",
            text: "Success!",
            icon: "success",
            confirmButtonClass: "btn btn-primary",
          });
        });
      this.$bvModal.hide("modal-center");
    },
    CloseModel() {
      this.$bvModal.hide("CurrentPayroll");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },

    async Apply() {
      this.freshRecord = [];
      this.loader = true;
      this.$store.dispatch("resetTimePeriod");
      setTimeout(async () => {
        var startDate;
        var endDate;
        if (this.form.dates.startDate) {
          startDate = this.dobformat2(this.form.dates.startDate);
        }
        if (this.form.dates.endDate) {
          endDate = this.dobformat2(this.form.dates.endDate);
        }
        if (this.form.batchName) {
          await this.$store
            .dispatch("getTimePeriod", {
              batchName: this.form.batchName,
              payDate: this.form.date,
              startdate: startDate,
              enddate: endDate,
            })
            .then((response) => {
              this.loader = false;
              if (response.status) {
                this.freshRecord = response.data.data;
                // this.$bvModal.show("CurrentPayroll");
                this.loader = false;
              } else {
                this.loader = false;
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
            })
            .catch((ex) => {
              this.isfetching = false;
            });
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.text-color {
  color: #2d79bd;
}
.table thead th,
.table thead td {
  padding: 0px 40px !important;
  font-weight: 600;
  font-size: 1rem;
  border-bottom-width: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>