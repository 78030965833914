<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="menu-icon flaticon-clock-2"></i><span class="ml-3 text-color">View Current Payroll</span>
      </h3>
    </div>
    <div style="
        border: 1px solid black;
        background: white;
        padding: 5rem 2rem;
        margin: 10vh 25%;
        width: 40%;
      ">
      <!-- <div :class="{ invalid: $v.form.batchName.$error }">
        <v-text-field
          v-model="$v.form.batchName.$model"
          persistent-hint
          label="Batch Name"
          class="my-0 pt-0"
        ></v-text-field>

        <div class="invalid-feedback" v-if="$v.form.batchName.$error">
          <span v-if="$v.form.batchName.$error"
            >create a name for this batch</span
          >
        </div>
      </div> -->
      <div>
        <div>
          <span class="text-color"> <b>Department</b> </span> <br />
          <b-form-group label="Using options array:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="checkbox-group-1" v-model="form.departments" :options="options"
              :aria-describedby="ariaDescribedby" name="flavour-1"
              style="display: flex; flex-direction: column"></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div>
          <span class="text-color"> <b>Service Period</b> </span> <br />
          <span>Select the Service period for this batch </span>
        </div>
        <date-range-picker ref="picker" open="center" :max-date="computedDate" :min-date="computedDateMin"
          v-model="form.dates" :ranges="false" prepend-icon="mdi-bell">
          <div slot="footer" slot-scope="data" class="slot">
            <div class="float-right mb-5">
              <span v-if="data.rangeText != ' - '">
                {{ data.rangeText }}
              </span>
              <span class="m-4">
                <a @click="clearRange" v-if="!data.in_selection" class="btn btn-success btn-sm">Clear</a>
              </span>
              <span>
                <a @click="data.clickCancel" v-if="!data.in_selection" class="btn btn-success btn-sm">Cancel</a>
              </span>
              <span class="m-4">
                <a @click="Setdate(data)" v-if="!data.in_selection" class="btn btn-success btn-sm">Apply</a>
              </span>
            </div>
          </div>
        </date-range-picker>
      </div>

      <div class="float-right">
        <!-- <span>
          <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
        </span> -->
        <span class="m-4">
          <a @click="Apply" class="btn btn-success btn-sm">Submit
            <span v-if="loader">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </span>
          </a>
        </span>
      </div>
    </div>
    <b-modal size="xl" centered text id="CurrentPayroll" hide-footer no-close-on-backdrop>
      <template #modal-header="{ close }">
        <h5>View Current Payroll</h5>
        <!-- <a
          @click="differPayroll"
          class="btn btn-success btn-sm"
          style="margin-left: 40%"
          >Select Different Employee</a
        > -->
        <v-icon class="btn btn-sm close-btn" @click="close()">mdi-close</v-icon>
      </template>

      <currentPayroll :form="form"></currentPayroll>
    </b-modal>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import currentPayroll from "../../components/payroll/currentPayrollprocess.vue";
export default {
  components: { DateRangePicker, currentPayroll },
  mixins: [validationMixin],
  validations: {
    form: {
      batchName: { required },
    },
  },
  data() {
    return {
      selected: [],
      options: [
        { text: "Regional Center - Non Medical", value: "Admin Level 2" },
        { text: "Regional Center - Skilled Nursing", value: "Home Health" },
        { text: "Senior Care - Non Medical", value: "JFS" },
      ],
      form: {
        batchName: "Current Payroll File",
        departments: [],
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dates: {
          startDate: null,
          endDate: null,
        },
      },

      menu: false,
      selectedDepartment:[],
      loader: false,
    };
  },
  watch: {},
  created() { },
  computed: {
    ...mapGetters(["TimePeriod", "Batch"]),
    computedDate() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      return new Date();
    },
    computedDateMin() {
      return new Date().setMonth(new Date().getMonth() - 11);
    },
    formatDate2() {
      if (!this.form.date) return null;

      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
    ScheduleDate() {
      if (!this.form.date) return null;
      const [year, month, day] = this.form.date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
  mounted() {
    // const now = new Date();
    // if (new Date().getDate() > 16) {
    //   this.form.dates = {
    //     startDate: new Date().setDate(16),
    //     endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
    //   };
    // } else {
    //   this.form.dates = {
    //     startDate: new Date(now.getFullYear(), now.getMonth(), 1),
    //     endDate: new Date().setDate(15),
    //   };
    // }
  },
  methods: {
    Setdate(data) {
      data.clickApply();
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      // if (new Date(this.form.dates.startDate).getDate() > 16) {
      //   this.form.dates.startDate = new Date(y, m, 16);
      //   this.form.dates.endDate = new Date(y, m + 1, 0);
      // } else {
      //   this.form.dates.startDate = new Date(y, m, 1);
      //   this.form.dates.endDate = new Date(y, m, 16);
      // }
    },
    clearRange() {
      this.form.dates = {
        startDate: null,
        endDate: null,
      };
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    async Apply() {
      this.$v.form.$touch();
      this.form.selectedDepartment = []
      if (!this.$v.form.$invalid) {
        if (this.form.departments.includes('Admin Level 2')) {
          this.form.selectedDepartment.push('Regional Center - Non Medical')
          this.form.departments.push('Family Referred')
          this.form.departments.push('Agency Caregiver')
        }
        else if (this.form.departments.includes('Home Health')) {
          this.form.selectedDepartment.push('Regional Center - Skilled Nursing')

        } else if (this.form.departments.includes('JFS')) {
          this.form.selectedDepartment.push('Senior Care - Non Medical')

        }
        if (!this.Batch.some((x) => x.batchName == this.form.batchName)) {
          this.loader = true;
          var bn = this.form.batchName;
          var obj = this.Batch;
          var startDate;
          var endDate;
          if (this.form.dates.startDate) {
            startDate = this.dobformat2(this.form.dates.startDate);
          }
          if (this.form.dates.endDate) {
            endDate = this.dobformat2(this.form.dates.endDate);
          }
          if (this.form.batchName) {
            await this.$store
              .dispatch("getTimePeriod", {
                batchName: this.form.batchName,
                payDate: this.form.date,
                startdate: startDate,
                enddate: endDate,
              })
              .then((response) => {
                this.loader = false;
                if (response.status) {
                  this.$bvModal.show("CurrentPayroll");
                  this.loader = false;
                } else {
                  this.loader = false;
                  Swal.fire({
                    title: "",
                    text: response.message,
                    icon: "error",
                    confirmButtonClass: "btn btn-danger",
                  });
                }
              })
              .catch((ex) => {
                this.isfetching = false;
              });
          }
        } else {
          Swal.fire({
            title: "",
            text: "Batch name already exists",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          });
        }
      }
    },
    RESET() {
      this.$v.$reset();
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      this.form.dates.startDate = new Date(y, m, 1);
      this.form.dates.endDate = new Date(y, m, 16);
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}

.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}

.text-color {
  color: #2d79bd;
}

.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>
